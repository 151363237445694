'use strict';

var $ = require('../internals/export');
var globalThis = require('../internals/global-this');
var setToStringTag = require('../internals/set-to-string-tag');
$({
  global: true
}, {
  Reflect: {}
});

// Reflect[@@toStringTag] property
// https://tc39.es/ecma262/#sec-reflect-@@tostringtag
setToStringTag(globalThis.Reflect, 'Reflect', true);