'use strict';

var $ = require('../internals/export');
var globalThis = require('../internals/global-this');

// `globalThis` object
// https://tc39.es/ecma262/#sec-globalthis
$({
  global: true,
  forced: globalThis.globalThis !== globalThis
}, {
  globalThis: globalThis
});