import type { Environment } from '@tytapp/environment';
import { ENV_OVERRIDES } from './environment-switcher';
import deepmerge from 'deepmerge';

const hasWindow = typeof globalThis['window'] !== 'undefined';
const hasWorkerScope = typeof globalThis['WorkerGlobalScope'] !== 'undefined';

/**
 * Returns true if we are running in the browser in a non-worker state.
 * @returns
 */
export const isClientSide = (hasWindow && !hasWorkerScope) ? () => true : () => false;

/**
 * Returns true if we are running in SSR.
 * @returns
 */
export const isServerSide = (!hasWindow && !hasWorkerScope) ? () => true : () => false;

/**
 * Returns true if we are running in a Web Worker on the client side.
 * @returns
 */
export const isWorkerSide = (!hasWindow && hasWorkerScope) ? () => true : () => false;

/**
 * Returns true if we are not online.
 * @returns
 */
export function isOffline() {
    return !isOnline();
}

export class OfflineError extends Error {
    constructor(readonly cause: string) {
        super('No network connection is available.');
    }
}

/**
 * Check the connectivity status of the client. On the server side this is always true. On the client/worker sides this is
 * true when navigator.onLine is true. On the client side (non-worker) supports overriding online state with a localStorage
 * value.
 * @returns
 */
export function isOnline() {
    if (isClientSide() && localStorage['tyt:online'] !== undefined) {
        return localStorage['tyt:online'] === '1';
    }


    if (isClientSide() || isWorkerSide()) {
        return navigator.onLine;
    }

    return true;
}

/**
 * Similar to isOnline(), except this will show an alert dialog when the user is not online. Use this for a consistent
 * error experience when checking for online status.
 * @returns
 */
export function networkAvailableForAction() {
    if (!isOnline()) {
        showErrorDueToOffline();
        return false;
    }

    return true;
}

export function showErrorDueToOffline() {
    alert(
        `A network connection is required to perform this action. `
        + `Please check your Internet connectivity and try again.`
    );
}

/**
 * Takes a URL that may be targeting a different environment (ie staging/production/dev, web/mobile) and conforms
 * it to the current origin so that it can be interpreted as a local link.
 * @param env
 * @param urlString
 * @returns
 */
export function rewriteUrl(env: Environment, urlString: string) {
    if (typeof urlString !== 'string')
        return urlString;

    if (!urlString || urlString.startsWith('javascript:') || urlString.startsWith('mailto:'))
        return urlString;

    let originalUrl = urlString;

    urlString = rewriteUrlToLocalOrigin(env, env.urls.canonicalRoot, urlString);
    urlString = rewriteUrlToLocalOrigin(env, 'https://tyt.com', urlString);
    urlString = rewriteUrlToLocalOrigin(env, 'http://localhost:4200', urlString);
    urlString = rewriteUrlToLocalOrigin(env, 'http://mobile.tyt.com', urlString);
    urlString = rewriteUrlToLocalOrigin(env, 'https://mobile.tyt.com', urlString);
    urlString = rewriteUrlToLocalOrigin(env, 'tyt://mobile.tyt.com', urlString);

    //alert(`rewrote from ${originalUrl} -> ${urlString}`);

    return urlString;
}

function rewriteUrlToLocalOrigin(env: Environment, rootUrlString: string, urlString: string) {
    let origin = isClientSide() ? location.origin : env.urls.webRoot;

    if (urlString.startsWith('/')) {
        urlString = `${origin}${urlString}`;
    }

    let url: URL;

    try {
        url = new URL(urlString);
    } catch (e) {
        console.error(`rewriteUrlToLocalOrigin(): Failed to parse URL '${urlString}': ${e.message}`);
        return urlString;
    }

    let rootUrl = new URL(rootUrlString);

    if (url.origin === rootUrl.origin && origin !== rootUrl.origin) {
        url = new URL(`${url.pathname}${url.search ? `${url.search}` : ``}`, origin);
        return url.toString();
    }

    return urlString;
}


interface EnvironmentSnippet {
    name: string;
}

/**
 * This mechanism aims to prevent accidental inclusion of a
 * direct environment file (instead of @tytapp/environment)
 * by raising an error when multiple environments are registered.
 *
 * [1] We intentionally use concatenation so that this file does not appear on a codebase search to make it
 *     easy and fast to fix these issues.
 */
let registeredEnvironments: Environment[] = [];
export function ensureCorrectImports(environment: Environment) {
    registeredEnvironments.push(environment);
    if (registeredEnvironments.length > 1) {
        throw new Error(
            `Multiple environments have been loaded!\n`
            + `This happens when code inadvertently imports a direct environment file.\n`
            + `- Incorrect: @tytapp`+`/environments/environment.staging\n` // NOTE: extra + is intentional, see [1]
            + `- Correct: @tytapp/environment\n\n`
            + `Loaded environments:\n`
            + `  ${registeredEnvironments.map(x => x.name).join("\n  ")}`
            + `\n\n`
            + `Search codebase for '@tytapp`+`/environments/environment' and fix all instances\n`  // NOTE: extra + is intentional, see [1]
            + `before continuing!\n\n`
        );
    }
}

/**
 * Create a new Environment. These should exist in src/environments and be named
 * "environment.<name>.ts". They will be referenced within different configurations in
 * angular.json to change static configuration parameters. These files are sent to the client,
 * so they must not include any private secrets.
 *
 * The main features enabled by this function call are:
 * - Ability to override the environment details at runtime (see environment-switcher.ts)
 * - Correct handling of mobile.tyt.com within webview for TYT Native
 * - Validation of correct imports (avoids importing an environment file directly)
 *
 * @param environment
 * @returns
 */
export function makeEnvironment<T>(environment: T): T {
    ensureCorrectImports(environment as any);
    mobileOverrides(environment as any);
    applyOverrides(environment as any);
    return environment;
}

export function mobileOverrides(environment: Environment) {
    if (isClientSide() && location.hostname === 'mobile.tyt.com') {
        environment.urls.root = location.origin;
        environment.auth.domain = '.mobile.tyt.com';
        environment.cookies.storeInLocalStorage = true;
    }
}

export function applyOverrides(environment: Environment) {
    if (isClientSide()) {
        const localStorage = globalThis['localStorage'];
        let name = localStorage.getItem('tyt-api-override');

        if (name) {
            Object.assign(environment, deepmerge(environment, ENV_OVERRIDES[name]));
            environment.urls.webRoot = environment.urls.canonicalRoot;
            environment.apiOverride = name;
        }

        let native = localStorage['tyt-native-build-override'];
        if (native !== undefined) {
            environment.isNativeBuild = native === '1';
        }

        let productName = localStorage['tyt-product-name-override'];
        if (productName !== undefined) {
            environment.productName = productName;
        }

    }
}
