import type { Environment } from './environment';
type DeepPartial<T> = T extends object ? { [P in keyof T]?: DeepPartial<T[P]>; } : T;

export const ENV_OVERRIDES: Record<string, DeepPartial<Environment>> = {
    'staging': {
        urls: {
            canonicalRoot: 'https://app.staging.tyt.com',
            api: 'https://platform.staging.tyt.com/api/v1',
            bantaServer: 'https://banta.staging.tyt.com',
            forums: 'https://discuss.staging.tyt.com',
            platform: 'https://platform.staging.tyt.com'
        }
    },
    'tytdev': {
        urls: {
            canonicalRoot: 'https://app.tytdev.docker',
            api: 'https://platform.tytdev.docker/api/v1',
            bantaServer: 'https://banta.tytdev.docker',
            forums: 'https://discuss.tytdev.docker',
            platform: 'https://platform.tytdev.docker'
        }
    },
    'production': {
        urls: {
            canonicalRoot: 'https://tyt.com',
            api: 'https://platform.tyt.com/api/v1',
            bantaServer: 'https://banta.tyt.com',
            forums: 'https://discuss.tyt.com',
            platform: 'https://platform.tyt.com'
        }
    }
}
